<template>
  <v-container id="dashboard-view" fluid tag="section" class="px-8">
    <!--  <v-row no-gutters align="center" class="pb-4">
      <v-col md="5" cols="12" lg="5" align="start">
        <h2>
          <v-icon :color="color" large left>{{ icon }}</v-icon>
          Dashboard
        </h2>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>-->

    <v-toolbar
      :prominent="$vuetify.breakpoint.smAndDown ? true : false"
      color="transparent"
      class="my-5 rounded-lg"
    >
      <v-row
        :no-gutters="$vuetify.breakpoint.smAndDown ? true : false"
        align="center"
      >
        <v-col cols="6" sm="2" md="2" class="px-1">
          <v-card-title class="pepsico-blue">Filters</v-card-title>
        </v-col>
        <v-col cols="6" sm="4" md="4" class="px-1">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.date_from"
                label="Date from"
                hide-details
                outlined
                dense
                readonly
                class="font-weight-bold text-subtitle-1"
                clearable
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.date_from"
              show-current
              type="month"
              :color="color"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="4" md="4" class="px-1">
          <v-menu
            v-model="dateMenu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.date_to"
                label="Date to"
                hide-details
                outlined
                dense
                readonly
                class="font-weight-bold text-subtitle-1"
                clearable
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.date_to"
              type="month"
              :color="color"
              show-current
              @input="dateMenu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="6" md="2" class="px-1">
          <v-btn
            depressed
            outlined
            color="#284389"
            @click="applyFilters(true)"
            :loading="loading"
          >
            <v-icon left v-if="$vuetify.breakpoint.mdAndUp"
              >mdi-filter-variant</v-icon
            >
            Apply Filters
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row justify="start">
      <v-col
        cols="12"
        md="4"
        class=""
        lg="4"
        v-for="item in cardData"
        :key="item.name"
      >
        <v-card
          :elevation="!item.active ? '4' : ''"
          @click="activeCard(item)"
          class="rounded-lg"
          :class="item.active ? 'active-class' : ''"
        >
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="#284389"
            ></v-progress-circular>
          </div>
          <p
            v-else
            class="text-h4 pb-0 align-center mb-0 pt-3 pepsico-blue"
            :title="numberWithSpaces(item.titleNumber)"
          >
            {{ limitSize(numberWithSpaces(item.titleNumber), 20) }}
            <span class="text-caption" v-if="item.addTitleCaption">{{
              item.addTitleCaption
            }}</span>
          </p>
          <p
            class="mb-0 grey--text text--darken-2 font-weight-bold"
            v-if="item"
          >
            {{ item.subtitle }}
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-3 px-3" justify="center" align="center">
      <v-col cols="12" class="pa-0">
        <v-card class="rounded-lg">
          <v-card-title class="pl-0">
            <v-col
              md="3"
              cols="12"
              class="pl-0"
              align-self="center"
              v-if="selected"
            >
              <v-btn
                large
                depressed
                outlined
                color="#284389"
                @click="exportDataToExcel"
                :loading="exportLoading"
              >
                <v-icon>mdi-download-outline</v-icon>
                Export
              </v-btn>
              {{ selected.subtitle }}</v-col
            >
            <v-col md="6" sm="12" align-self="center" align="center">
              <v-radio-group
                v-model="filter.report_type"
                row
                hide-details
                class="ma-0"
              >
                <v-radio
                  v-for="type in chartFilters"
                  :key="type.value"
                  class="font-weight-medium"
                  :label="type.label"
                  :value="type.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col md="3" sm="12" xs="12" align-self="center">
              <span class="body-1 grey--text pa-2">Chat type</span>
              <v-btn-toggle v-model="type">
                <v-btn depressed icon value="bar" color="transparent">
                  <v-icon>mdi-chart-bar</v-icon>
                </v-btn>
                <v-btn icon value="line" color="transparent">
                  <v-icon>mdi-chart-line-variant</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-card-title>
          <v-card-text>
            <bar-chart
              v-if="type === 'bar'"
              :chart-data="chartData"
            ></bar-chart>
            <line-chart
              v-if="type === 'line'"
              :chart-data="chartData"
            ></line-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import BarChart from "@/components/core/charts/BarChart";
import LineChart from "@/components/core/charts/LineChart";
import string from "@/mixins/string";
import downloadExcel from "@/mixins/downloadExcel";
import _ from "lodash";

export default {
  name: "DashboardView",
  components: { LineChart, BarChart },
  mixins: [string, downloadExcel],
  data() {
    return {
      type: "bar",
      dateMenu: false,
      dateMenu2: false,
      exportLoading: false,
      backgroundColor: "#2196F3",
    };
  },
  async created() {
    this.$store.commit("dashboard/setSelected", null);
    await this.$store.dispatch("dashboard/totalEmployees", true);
    await this.$store.dispatch("dashboard/totalCost", true);
    await this.$store.dispatch("dashboard/contractTermination", true);
    await this.$store.dispatch("dashboard/overTime", true);
    await this.$store.dispatch("dashboard/sickLeave", true);
    this.$store.commit("dashboard/setChartData");
    //  this.$store.dispatch("dashboard/setCompareValues");
  },
  watch: {
    "filter.report_type": function (newVal) {
      this.applyFilters(false);
    },
  },
  computed: {
    ...mapGetters({
      loading: "dashboard/loading",
      cardData: "dashboard/cardData",
      selected: "dashboard/selected",
      filter: "dashboard/filter",
      totalCost: "dashboard/totalCost",
      chartData: "dashboard/chartData",
      chartRawData: "dashboard/chartRawData",
      totalEmployees: "dashboard/totalEmployees",
      overTime: "dashboard/overTime",
      sickLeave: "dashboard/sickLeave",
      contractTermination: "dashboard/contractTermination",
    }),
    color() {
      return this.$route.meta.color || "";
    },
    icon() {
      return this.$route.meta.icon || "";
    },
    chartFilters() {
      return [
        { label: "Date", value: "by_date", color: "#44a3ed" },
        { label: "Job position", value: "by_job_position", color: "#017d40" },
        { label: "Job location", value: "by_job_location", color: "#ee7f00" },
        { label: "City", value: "by_job_location_city", color: "#ee7f00" },
      ];
    },
  },
  methods: {
    activeCard(item) {
      this.$store.commit("dashboard/setSelected", item);
      this.cardData.find((el) => {
        el.active = el.subtitle === item.subtitle;
      });
      this.applyFilters(false);
    },
    numberWithSpaces(string) {
      var x = string % 1 != 0 ? parseFloat(string).toFixed(2) : string;
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    async applyFilters(recalculateTotal = true) {
      await this.$store.dispatch("dashboard/totalEmployees", recalculateTotal);
      await this.$store.dispatch("dashboard/totalCost", recalculateTotal);
      await this.$store.dispatch("dashboard/contractTermination", recalculateTotal);
      await this.$store.dispatch("dashboard/overTime", recalculateTotal);
      await this.$store.dispatch("dashboard/sickLeave", recalculateTotal);
      this.$store.commit("dashboard/setChartData");
      /*  if(recalculateTotal){
        this.$store.dispatch("dashboard/setCompareValues");
      }*/
    },
    exportDataToExcel() {
      this.exportLoading = true;
      const headers = [];
      const firstItem = this.chartRawData[0] || {};
      for (const [key, value] of Object.entries(firstItem)) {
        let keyText = key;
        if (key === "number") keyText = "Employees";
        headers.push({ text: _.startCase(keyText), value: key });
      }
      this.exportToExcel(
        headers,
        this.chartRawData,
        this.selected.subtitle +
          " " +
          this.filter.report_type +
          " " +
          this.filter.date_from +
          ".xlsx"
      );
      this.exportLoading = false;
    },
  },
};
</script>
<style>
.pepsico-blue {
  color: #284389 !important;
}

.active-class {
  background-color: #b2ddff63 !important;
  box-shadow: 0px 7px 7px -4px rgb(32 133 255 / 15%),
    0px 3x 9px 2px rgb(169 202 237 / 15%),
    0px 5px 14px 4px rgb(211 221 253 / 15%) !important;
}
</style>
