<script type="text/javascript">
import {Bar, mixins} from 'vue-chartjs'

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ['chartData'],
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        align: 'start',
        position: 'top',
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            precision:0
          }
        }]
      }

    },
  }),
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}

</script>
