<script type="text/javascript">
import {Line, mixins} from 'vue-chartjs'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData'],
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        align: 'start',
        position: 'top',
      },
      tooltips: {
        mode: 'index',
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            precision:0
            }
        }]
      }
    },
  }),
  mounted() {
    this.renderChart(this.chartData, this.options)
  },

}

</script>
